import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

const prefix: any = 'custom'

const crossSquare: IconDefinition = {
  icon: [448, 512, [], '', 'M400,32 L48,32 C21.5,32 0,53.5 0,80 L0,432 C0,458.5 21.5,480 48,480 L400,480 C426.5,480 448,458.5 448,432 L448,80 C448,53.5 426.5,32 400,32 Z M48,148.084 L400,420.141 L400,426 C400,429.3 397.3,432 394,432 L54,432 C50.7,432 48,429.3 48,426 L48,148.084 Z M394,80 C397.3,80 400,82.7 400,86 L400,363.513 L48,91.456 L48,86 C48,82.7 50.7,80 54,80 L394,80 Z'],
  iconName: 'cross-square' as any,
  prefix
}

const text: IconDefinition = {
  icon: [448, 512, [], 'f893', 'M432 32a16 16 0 0 1 16 16v96a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32H264v304h40a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H144a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h40V112H64v32a16 16 0 0 1-16 16H16a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16z'],
  iconName: 'text' as any,
  prefix
}

const selection: IconDefinition = {
  icon: [512, 512, [], '', [
    'M0,0 C83.1454349,0 167.77416,0 253.886176,0 C339.998191,0 426.110207,0 512.222222,0 L512,512 L0,512 L0,0 Z',
    'M376,42 L376,0 L418,0 L418,42 L376,42 M469.777778,42 L469.777778,0 L511.777778,0 L511.777778,42 L469.777778,42 M282,42 L282,0 L324,0 L324,42 L282,42 M188,42 L188,0 L230,0 L230,42 L188,42 M-0.222222222,42 L-0.222222222,0 L41.7777778,0 L41.7777778,42 L-0.222222222,42 M94,42 L94,0 L136,0 L136,42 L94,42 M281.777778,512 L281.777778,470 L323.777778,470 L323.777778,512 L281.777778,512 M375.777778,512 L375.777778,470 L417.777778,470 L417.777778,512 L375.777778,512 M469.777778,512 L469.777778,470 L511.777778,470 L511.777778,512 L469.777778,512 M469.777778,418 L469.777778,376 L511.777778,376 L511.777778,418 L469.777778,418 M187.777778,512 L187.777778,470 L229.777778,470 L229.777778,512 L187.777778,512 M93.7777778,512 L93.7777778,470 L135.777778,470 L135.777778,512 L93.7777778,512 M-0.222222222,512 L-0.222222222,470 L41.7777778,470 L41.7777778,512 L-0.222222222,512 M-0.222222222,418 L-0.222222222,376 L41.7777778,376 L41.7777778,418 L-0.222222222,418 M0,282 L42,282 L42,324 L0,324 L0,282 M511.777778,282 L511.777778,324 L469.777778,324 L469.777778,282 L511.777778,282 M0,188 L42,188 L42,230 L0,230 L0,188 M511.777778,188 L511.777778,230 L469.777778,230 L469.777778,188 L511.777778,188 M0,94 L42,94 L42,136 L0,136 L0,94 M511.777778,94 L511.777778,136 L469.777778,136 L469.777778,94 L511.777778,94 Z'
  ]],
  iconName: 'selection' as any,
  prefix
}

const connection: IconDefinition = {
  icon: [512, 512, [], '', 'M373.202741,220.000605 C381.535524,220.000605 389.533273,223.28135 395.465642,229.133038 L414.473015,247.881906 C437.760439,270.852614 462.299301,292.51905 487.977397,312.782143 C504.776715,326.038818 507.648595,350.404014 494.39192,367.203332 C492.512275,369.585285 490.359349,371.73821 487.977397,373.617857 C462.299301,393.88095 437.760439,415.547386 414.473015,438.518094 L395.465642,457.266962 C383.000654,469.562422 362.928347,469.424993 350.632885,456.960005 C344.781198,451.027635 341.500452,443.029887 341.500452,434.697106 L341.5004,373.615 L40.4178564,373.617857 C23.6185382,373.617857 10,359.999318 10,343.2 C10,326.400682 23.6185382,312.782143 40.4178564,312.782143 L341.5004,312.7696 L341.500452,251.702894 C341.500452,234.194205 355.69405,220.000605 373.202741,220.000605 Z M472,66 C489.01037,66 502.8,79.7896297 502.8,96.8 C502.8,113.81037 489.01037,127.6 472,127.6 L40.8,127.6 C23.7896297,127.6 10,113.81037 10,96.8 C10,79.7896297 23.7896297,66 40.8,66 L472,66 Z'],
  iconName: 'connection' as any,
  prefix
}

const questionCircle: IconDefinition = {
  icon: [512, 512, [], 'f059', 'M256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm7.67-24h-16c-6.627 0-12-5.373-12-12v-.381c0-70.343 77.44-63.619 77.44-107.408 0-20.016-17.761-40.211-57.44-40.211-29.144 0-44.265 9.649-59.211 28.692-3.908 4.98-11.054 5.995-16.248 2.376l-13.134-9.15c-5.625-3.919-6.86-11.771-2.645-17.177C185.658 133.514 210.842 116 255.67 116c52.32 0 97.44 29.751 97.44 80.211 0 67.414-77.44 63.849-77.44 107.408V304c0 6.627-5.373 12-12 12zM256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8z'],
  iconName: 'question-circle' as any,
  prefix
}

const fileUser: IconDefinition = {
  icon: [384, 512, [], '', 'M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm80 273.6v19.2c0 10.61-10.03 19.2-22.4 19.2H102.4c-12.37 0-22.4-8.6-22.4-19.2v-19.2c0-31.81 30.09-57.6 67.2-57.6h4.95c12.29 5.12 25.73 8 39.85 8s27.56-2.88 39.85-8h4.95c37.11 0 67.2 25.79 67.2 57.6zM192 320c-35.35 0-64-28.65-64-64s28.65-64 64-64 64 28.65 64 64-28.65 64-64 64zm185-215L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z'],
  iconName: 'file-user' as any,
  prefix
}

const sync: IconDefinition = {
  icon: [512, 512, [], 'f021', 'M500 8h-27.711c-6.739 0-12.157 5.548-11.997 12.286l2.347 98.575C418.212 52.043 342.256 8 256 8 134.813 8 33.933 94.924 12.296 209.824 10.908 217.193 16.604 224 24.103 224h28.576c5.674 0 10.542-3.982 11.737-9.529C83.441 126.128 161.917 60 256 60c79.545 0 147.942 47.282 178.676 115.302l-126.39-3.009c-6.737-.16-12.286 5.257-12.286 11.997V212c0 6.627 5.373 12 12 12h192c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12zm-12.103 280h-28.576c-5.674 0-10.542 3.982-11.737 9.529C428.559 385.872 350.083 452 256 452c-79.546 0-147.942-47.282-178.676-115.302l126.39 3.009c6.737.16 12.286-5.257 12.286-11.997V300c0-6.627-5.373-12-12-12H12c-6.627 0-12 5.373-12 12v192c0 6.627 5.373 12 12 12h27.711c6.739 0 12.157-5.548 11.997-12.286l-2.347-98.575C93.788 459.957 169.744 504 256 504c121.187 0 222.067-86.924 243.704-201.824 1.388-7.369-4.308-14.176-11.807-14.176z'],
  iconName: 'sync' as any,
  prefix
}

const horizontalRule: IconDefinition = {
  icon: [640, 512, [], 'f86c', 'M640 239.87v31.26A15.88 15.88 0 0 1 624.14 287H15.87A15.88 15.88 0 0 1 0 271.13v-31.26A15.88 15.88 0 0 1 15.87 224h608.27A15.88 15.88 0 0 1 640 239.87z'],
  iconName: 'horizontal-rule',
  prefix
}

const regularWaveSquare: IconDefinition = {
  icon: [640, 512, [], 'f83e', 'M472 480H328a32 32 0 0 1-32-32V80H184v168a32 32 0 0 1-32 32H8a8 8 0 0 1-8-8v-32a8 8 0 0 1 8-8h128V64a32 32 0 0 1 32-32h144a32 32 0 0 1 32 32v368h112V264a32 32 0 0 1 32-32h144a8 8 0 0 1 8 8v32a8 8 0 0 1-8 8H504v168a32 32 0 0 1-32 32z'],
  iconName: 'regular-wave-square' as any,
  prefix
}

const regularSquare: IconDefinition = {
  icon: [448, 512, [], 'f0c8', 'M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z'],
  iconName: 'regular-square' as any,
  prefix
}

const arrowToRight: IconDefinition = {
  icon: [448, 512, [], 'f340', 'M448 88v336c0 13.3-10.7 24-24 24h-24c-13.3 0-24-10.7-24-24V88c0-13.3 10.7-24 24-24h24c13.3 0 24 10.7 24 24zm-280.5 66.4l65.6 65.6H24c-13.3 0-24 10.7-24 24v24c0 13.3 10.7 24 24 24h209.1l-65.6 65.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L353.9 273c9.4-9.4 9.4-24.6 0-33.9L218.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9z'],
  iconName: 'arrow-to-right' as any,
  prefix
}

const arrowFromRight: IconDefinition = {
  icon: [448, 512, [], 'f343', 'M296.1 99.5l148.4 148c4.7 4.7 4.7 12.3 0 17l-148.4 148c-4.7 4.7-12.3 4.7-17 0l-19.6-19.6c-4.8-4.8-4.7-12.5.2-17.1l97.1-93.7H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h248.8l-97.1-93.7c-4.8-4.7-4.9-12.4-.2-17.1l19.6-19.6c4.7-4.9 12.3-4.9 17-.2zM52 436V76c0-6.6-5.4-12-12-12H12C5.4 64 0 69.4 0 76v360c0 6.6 5.4 12 12 12h28c6.6 0 12-5.4 12-12z'],
  iconName: 'arrow-from-right' as any,
  prefix
}

const fileAlt: IconDefinition = {
  icon: [384, 512, [], 'f15c', 'M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z'],
  iconName: 'file-alt' as any,
  prefix
}

const expand: IconDefinition = {
  icon: [448, 512, [], 'f065', 'M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H48v100c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v24c0 6.6 5.4 12 12 12h100v100c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-24c-6.6 0-12 5.4-12 12v100H300c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-24c0-6.6-5.4-12-12-12H48V332c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z'],
  iconName: 'expand' as any,
  prefix
}

const compressAlt: IconDefinition = {
  icon: [448, 512, [], 'f422', 'M224 232v-95.005c0-21.382 25.851-32.09 40.971-16.971l27.704 27.704L404.888 35.515c4.686-4.686 12.284-4.686 16.971 0l22.627 22.627c4.686 4.686 4.686 12.284 0 16.971L332.272 187.326l27.704 27.704c15.119 15.119 4.411 40.97-16.971 40.97H248c-13.255 0-24-10.745-24-24zM43.112 476.485l112.213-112.213 27.704 27.704c15.12 15.119 40.971 4.411 40.971-16.971V280c0-13.255-10.745-24-24-24h-95.005c-21.382 0-32.09 25.851-16.971 40.971l27.704 27.704L3.515 436.888c-4.686 4.686-4.686 12.284 0 16.971l22.627 22.627c4.686 4.686 12.284 4.686 16.97-.001z'],
  iconName: 'compress-alt' as any,
  prefix
}

const regularSearch: IconDefinition = {
  icon: [512, 512, [], 'f002', 'M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'],
  iconName: 'regular-search' as any,
  prefix
}

const regularWaveSine: IconDefinition = {
  icon: [640, 512, [], 'f899', 'M628.41 261.07L613 256.63a15.88 15.88 0 0 0-19.55 10.16C572.85 329.76 511.64 432 464 432c-52.09 0-87.41-93.77-121.53-184.45C302.56 141.58 261.31 32 176 32 87.15 32 17.77 178.46.78 230.69a16 16 0 0 0 10.81 20.23L27 255.36a15.87 15.87 0 0 0 19.55-10.15C67.15 182.24 128.36 80 176 80c52.09 0 87.41 93.77 121.53 184.45C337.44 370.42 378.69 480 464 480c88.85 0 158.23-146.46 175.22-198.7a16 16 0 0 0-10.81-20.23z'],
  iconName: 'regular-wave-sine' as any,
  prefix
}

const solidTriangle: IconDefinition = {
  icon: [576, 512, [], 'f2ec', 'M329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.9 0 60-40 41.6-72l-240-416z'],
  iconName: 'solid-triangle' as any,
  prefix
}

const solidLockAlt: IconDefinition = {
  icon: [448, 512, [], 'f30d', 'M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zM264 392c0 22.1-17.9 40-40 40s-40-17.9-40-40v-48c0-22.1 17.9-40 40-40s40 17.9 40 40v48zm32-168H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z'],
  iconName: 'solid-lock-alt' as any,
  prefix
}

const solidSackDollar: IconDefinition = {
  icon: [512, 512, [], 'f81d', 'M192 96h128l47.4-71.12A16 16 0 0 0 354.09 0H157.94a16 16 0 0 0-13.31 24.88zm128 32H192C-10.38 243.4.09 396.64.09 416c0 53 49.11 96 109.68 96h292.48c60.58 0 109.68-43 109.68-96 0-19 9.35-173.24-191.93-288zm-46.58 278v17.34a8.69 8.69 0 0 1-8.7 8.62h-17.41a8.69 8.69 0 0 1-8.71-8.62v-17.51a63.19 63.19 0 0 1-34.16-12.17 8.55 8.55 0 0 1-.66-13l12.84-12.06a8.92 8.92 0 0 1 11-.76 26.72 26.72 0 0 0 13.93 4h30.58c7.07 0 12.84-6.35 12.84-14.22 0-6.46-3.92-12.06-9.58-13.67l-49-14.54c-20.24-6-34.39-25.2-34.39-46.74 0-26.38 20.68-47.82 46.46-48.57v-17.48a8.69 8.69 0 0 1 8.74-8.62h17.41a8.68 8.68 0 0 1 8.7 8.62v17.55a63.12 63.12 0 0 1 34.17 12.17 8.55 8.55 0 0 1 .65 13l-12.73 12.2a8.92 8.92 0 0 1-11 .75 26.78 26.78 0 0 0-13.93-4h-30.56c-7.07 0-12.84 6.35-12.84 14.21 0 6.46 3.92 12.06 9.57 13.68l49 14.54c20.24 6 34.38 25.2 34.38 46.74-.14 26.4-20.92 47.94-46.6 48.54z'],
  iconName: 'solid-sack-dollar' as any,
  prefix
}

const solidSledding: IconDefinition = {
  icon: [512, 512, [], 'f7cb', 'M505 420.1c-9.4-9.4-24.6-9.4-33.9 0-12.1 12.1-30.5 15.4-45.1 8.7l-49.1-25.4c4.2-5.4 7.2-11.9 7.2-19.4v-96c0-17.7-14.3-32-32-32h-50.8l46.1-46.1c13.8-13.8 17.9-34.3 10.4-52.3-7.5-18-24.9-29.6-44.3-29.6H160c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.8l-73.4 73.4c-3 2.9-5.3 6.5-6.9 10.4-2.1 5.1-2.4 10.5-1.8 15.9L35 226.6c-11.7-6-26.3-1.5-32.3 10.3-6.1 11.8-1.5 26.3 10.3 32.4l391.9 202.6c11.9 5.5 24.5 8.1 37.1 8.1 23.2 0 46-9 63-26 9.3-9.4 9.3-24.6 0-33.9zM320 374l-104.4-54H320v54zm80-246c26.5 0 48-21.5 48-48s-21.5-48-48-48-48 21.5-48 48 21.5 48 48 48z'],
  iconName: 'solid-sledding' as any,
  prefix
}

const regularMinus: IconDefinition = {
  icon: [384, 512, [], 'f068', 'M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'],
  iconName: 'regular-minus' as any,
  prefix
}

const regularPlus: IconDefinition = {
  icon: [384, 512, [], 'f067', 'M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'],
  iconName: 'regular-plus' as any,
  prefix
}

const regularCheck: IconDefinition = {
  icon: [512, 512, [], 'f00c', 'M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'],
  iconName: 'regular-check' as any,
  prefix
}

const regularTimes: IconDefinition = {
  icon: [320, 512, [], 'f00d', 'M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'],
  iconName: 'regular-times' as any,
  prefix
}

const regularMinusCircle: IconDefinition = {
  icon: [512, 512, [], 'f056', 'M140 284c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v32c0 6.6-5.4 12-12 12H140zm364-28c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z'],
  iconName: 'regular-minus-circle' as any,
  prefix
}

const regularAngleDoubleDown: IconDefinition = {
  icon: [320, 512, [], 'f103', 'M151.5 427.8L3.5 281c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 362.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0zm17-160l148-146.8c4.7-4.7 4.7-12.3 0-17l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L160 202.7 40.3 84.2c-4.7-4.7-12.3-4.7-17 0L3.5 104c-4.7 4.7-4.7 12.3 0 17l148 146.8c4.7 4.7 12.3 4.7 17 0z'],
  iconName: 'regular-angle-double-down' as any,
  prefix
}

const regularAngleDoubleUp: IconDefinition = {
  icon: [320, 512, [], 'f102', 'M168.5 84.2l148 146.8c4.7 4.7 4.7 12.3 0 17l-19.8 19.8c-4.7 4.7-12.3 4.7-17 0L160 149.3 40.3 267.8c-4.7 4.7-12.3 4.7-17 0L3.5 248c-4.7-4.7-4.7-12.3 0-17l148-146.8c4.7-4.7 12.3-4.7 17 0zm-17 160L3.5 391c-4.7 4.7-4.7 12.3 0 17l19.8 19.8c4.7 4.7 12.3 4.7 17 0L160 309.3l119.7 118.5c4.7 4.7 12.3 4.7 17 0l19.8-19.8c4.7-4.7 4.7-12.3 0-17l-148-146.8c-4.7-4.7-12.3-4.7-17 0z'],
  iconName: 'regular-angle-double-up' as any,
  prefix
}

const regularUndo: IconDefinition = {
  icon: [512, 512, [], 'f0e2', 'M12 8h27.711c6.739 0 12.157 5.548 11.997 12.286l-2.347 98.568C93.925 51.834 170.212 7.73 256.793 8.001 393.18 8.428 504.213 120.009 504 256.396 503.786 393.181 392.835 504 256 504c-63.926 0-122.202-24.187-166.178-63.908-5.113-4.618-5.354-12.561-.482-17.433l19.738-19.738c4.498-4.498 11.753-4.785 16.501-.552C160.213 433.246 205.895 452 256 452c108.322 0 196-87.662 196-196 0-108.322-87.662-196-196-196-79.545 0-147.941 47.282-178.675 115.302l126.389-3.009c6.737-.16 12.286 5.257 12.286 11.997V212c0 6.627-5.373 12-12 12H12c-6.627 0-12-5.373-12-12V20C0 13.373 5.373 8 12 8z'],
  iconName: 'regular-undo' as any,
  prefix
}

const dragHandle: IconDefinition = {
  icon: [512, 512, [], '', 'M163,205 C191.15625,205 214,227.84375 214,256 C214,284.15625 191.15625,307 163,307 C134.84375,307 112,284.15625 112,256 C112,227.84375 134.84375,205 163,205 Z M112,95 C112,123.15625 134.84375,146 163,146 C191.15625,146 214,123.15625 214,95 C214,66.84375 191.15625,44 163,44 C134.84375,44 112,66.84375 112,95 Z M112,417 C112,445.15625 134.84375,468 163,468 C191.15625,468 214,445.15625 214,417 C214,388.84375 191.15625,366 163,366 C134.84375,366 112,388.84375 112,417 Z M349,205 C377.15625,205 400,227.84375 400,256 C400,284.15625 377.15625,307 349,307 C320.84375,307 298,284.15625 298,256 C298,227.84375 320.84375,205 349,205 Z M298,95 C298,123.15625 320.84375,146 349,146 C377.15625,146 400,123.15625 400,95 C400,66.84375 377.15625,44 349,44 C320.84375,44 298,66.84375 298,95 Z M298,417 C298,445.15625 320.84375,468 349,468 C377.15625,468 400,445.15625 400,417 C400,388.84375 377.15625,366 349,366 C320.84375,366 298,388.84375 298,417 Z'],
  iconName: 'drag-handle' as any,
  prefix
}

const flowReply: IconDefinition = {
  icon: [512, 512, [], '', 'M124.819705,52.1932971 C134.604015,42.4019168 150.475242,42.4019168 160.259553,52.1932971 L171.521407,63.469291 C176.321658,68.2624721 178.965684,74.8018416 178.85964,81.5886467 C178.753596,88.3683822 175.904552,94.8229166 170.95584,99.4676364 L134.915077,133.309757 L324.910272,133.309757 C414.177968,133.309757 487.312863,203.680443 491.780843,291.845283 L491.646521,289.745615 C491.879817,293.407662 492,297.083848 492,300.760034 C492,392.890913 417.048221,467.849762 324.910272,467.849762 C309.880327,467.849762 300.37173,459.46523 300.37173,434.431816 C300.37173,409.405472 309.399595,401.013871 324.910272,401.013871 C358.886715,401.013871 390.551403,383.799422 409.031308,355.280702 C427.511212,326.769051 430.289561,290.834332 416.411958,259.812977 L415.853461,258.222319 C413.888115,253.987636 411.611707,249.858996 409.031308,245.878817 C390.551403,217.367166 358.886715,200.152718 324.910272,200.145648 L134.915077,200.145648 L170.95584,234.001908 C175.904552,238.646628 178.753596,245.101163 178.85964,251.880898 C178.965684,258.667703 176.321658,265.207073 171.521407,270.000254 L160.259553,281.247969 C150.475242,291.03935 134.604015,291.03935 124.819705,281.247969 L28.0299664,184.429953 C18.2456557,174.645642 18.2456557,158.774416 28.0299664,148.990105 L124.819705,52.1932971 L124.819705,52.1932971 Z'],
  iconName: 'flow-reply' as any,
  prefix
}

const regularRepeat: IconDefinition = {
  icon: [512, 512, [], 'f363', 'M512 256c0 83.813-68.187 152-152 152H136.535l55.762 54.545c4.775 4.67 4.817 12.341.094 17.064l-16.877 16.877c-4.686 4.686-12.284 4.686-16.971 0l-104-104c-4.686-4.686-4.686-12.284 0-16.971l104-104c4.686-4.686 12.284-4.686 16.971 0l16.877 16.877c4.723 4.723 4.681 12.393-.094 17.064L136.535 360H360c57.346 0 104-46.654 104-104 0-19.452-5.372-37.671-14.706-53.258a11.991 11.991 0 0 1 1.804-14.644l17.392-17.392c5.362-5.362 14.316-4.484 18.491 1.847C502.788 196.521 512 225.203 512 256zM62.706 309.258C53.372 293.671 48 275.452 48 256c0-57.346 46.654-104 104-104h223.465l-55.762 54.545c-4.775 4.67-4.817 12.341-.094 17.064l16.877 16.877c4.686 4.686 12.284 4.686 16.971 0l104-104c4.686-4.686 4.686-12.284 0-16.971l-104-104c-4.686-4.686-12.284-4.686-16.971 0l-16.877 16.877c-4.723 4.723-4.681 12.393.094 17.064L375.465 104H152C68.187 104 0 172.187 0 256c0 30.797 9.212 59.479 25.019 83.447 4.175 6.331 13.129 7.209 18.491 1.847l17.392-17.392a11.991 11.991 0 0 0 1.804-14.644z'],
  iconName: 'regular-repeat' as any,
  prefix
}

const regularCommentAltLines: IconDefinition = {
  icon: [512, 512, [], 'f4a6', 'M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 352c0 8.8-7.2 16-16 16H288l-12.8 9.6L208 428v-60H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288zm-96-216H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h224c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-96 96H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z'],
  iconName: 'regular-comment-alt-lines' as any,
  prefix
}

const solidCommentAltLines: IconDefinition = {
  icon: [512, 512, [], 'f4a8', 'M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm-80 216c0 8.8-7.2 16-16 16h-72v72c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-72h-72c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h72v-72c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v72h72c8.8 0 16 7.2 16 16v16z'],
  iconName: 'solid-comment-alt-plus' as any,
  prefix
}

const solidCommentAltDots: IconDefinition = {
  icon: [512, 512, [], 'f4a3', 'M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zM128 240c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z'],
  iconName: 'solid-comment-alt-dots' as any,
  prefix
}

const solidCommentAltCheck: IconDefinition = {
  icon: [512, 512, [], 'f4a2', 'M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm-77.9 163.8l-131 130c-4.3 4.3-11.3 4.3-15.6-.1l-75.7-76.3c-4.3-4.3-4.2-11.3.1-15.6l26-25.8c4.3-4.3 11.3-4.2 15.6.1l42.1 42.5 97.2-96.4c4.3-4.3 11.3-4.2 15.6.1l25.8 26c4.2 4.3 4.2 11.3-.1 15.5z'],
  iconName: 'solid-comment-alt-check' as any,
  prefix
}

const regularAt: IconDefinition = {
  icon: [512, 512, [], 'f1fa', 'M504 232C504 95.751 394.053 8 256 8 118.94 8 8 118.919 8 256c0 137.059 110.919 248 248 248 52.926 0 104.681-17.079 147.096-48.321 5.501-4.052 6.423-11.924 2.095-17.211l-15.224-18.597c-4.055-4.954-11.249-5.803-16.428-2.041C339.547 442.517 298.238 456 256 456c-110.28 0-200-89.72-200-200S145.72 56 256 56c109.469 0 200 65.02 200 176 0 63.106-42.478 98.29-83.02 98.29-19.505 0-20.133-12.62-16.366-31.463l28.621-148.557c1.426-7.402-4.245-14.27-11.783-14.27h-39.175a12.005 12.005 0 0 0-11.784 9.735c-1.102 5.723-1.661 8.336-2.28 13.993-11.923-19.548-35.878-31.068-65.202-31.068C183.412 128.66 120 191.149 120 281.53c0 61.159 32.877 102.11 93.18 102.11 29.803 0 61.344-16.833 79.749-42.239 4.145 30.846 28.497 38.01 59.372 38.01C451.467 379.41 504 315.786 504 232zm-273.9 97.35c-28.472 0-45.47-19.458-45.47-52.05 0-57.514 39.56-93.41 74.61-93.41 30.12 0 45.471 21.532 45.471 51.58 0 46.864-33.177 93.88-74.611 93.88z'],
  iconName: 'regular-at' as any,
  prefix
}

const regularSparkles: IconDefinition = {
  icon: [512, 512, [], 'f890', 'M324.42 103.16L384 128l24.84 59.58a8 8 0 0 0 14.32 0L448 128l59.58-24.84a8 8 0 0 0 0-14.32L448 64 423.16 4.42a8 8 0 0 0-14.32 0L384 64l-59.58 24.84a8 8 0 0 0 0 14.32zm183.16 305.68L448 384l-24.84-59.58a8 8 0 0 0-14.32 0L384 384l-59.58 24.84a8 8 0 0 0 0 14.32L384 448l24.84 59.58a8 8 0 0 0 14.32 0L448 448l59.58-24.84a8 8 0 0 0 0-14.32zM384 256a24 24 0 0 0-13.28-21.47l-104.85-52.42-52.4-104.84c-8.13-16.25-34.81-16.25-42.94 0l-52.41 104.84-104.84 52.42a24 24 0 0 0 0 42.94l104.84 52.42 52.41 104.85a24 24 0 0 0 42.94 0l52.4-104.85 104.85-52.42A24 24 0 0 0 384 256zm-146.72 34.53a24 24 0 0 0-10.75 10.74L192 370.33l-34.53-69.06a24 24 0 0 0-10.75-10.74L77.66 256l69.06-34.53a24 24 0 0 0 10.75-10.73L192 141.67l34.53 69.07a24 24 0 0 0 10.75 10.73L306.34 256z'],
  iconName: 'regular-sparkles' as any,
  prefix
}

const solidRepeat: IconDefinition = {
  icon: [512, 512, [], 'f363', 'M512 256c0 88.224-71.775 160-160 160H170.067l34.512 32.419c9.875 9.276 10.119 24.883.539 34.464l-10.775 10.775c-9.373 9.372-24.568 9.372-33.941 0l-92.686-92.686c-9.373-9.373-9.373-24.568 0-33.941l92.686-92.686c9.373-9.373 24.568-9.373 33.941 0l10.775 10.775c9.581 9.581 9.337 25.187-.539 34.464L170.067 352H352c52.935 0 96-43.065 96-96 0-13.958-2.996-27.228-8.376-39.204-4.061-9.039-2.284-19.626 4.723-26.633l12.183-12.183c11.499-11.499 30.965-8.526 38.312 5.982C505.814 205.624 512 230.103 512 256zM72.376 295.204C66.996 283.228 64 269.958 64 256c0-52.935 43.065-96 96-96h181.933l-34.512 32.419c-9.875 9.276-10.119 24.883-.539 34.464l10.775 10.775c9.373 9.372 24.568 9.372 33.941 0l92.686-92.686c9.373-9.373 9.373-24.568 0-33.941l-92.686-92.686c-9.373-9.373-24.568-9.373-33.941 0L306.882 29.12c-9.581 9.581-9.337 25.187.539 34.464L341.933 96H160C71.775 96 0 167.776 0 256c0 25.897 6.186 50.376 17.157 72.039 7.347 14.508 26.813 17.481 38.312 5.982l12.183-12.183c7.008-7.008 8.786-17.595 4.724-26.634z'],
  iconName: 'solid-repeat' as any,
  prefix
}

const solidHorizontalRule: IconDefinition = {
  icon: [640, 512, [], 'f86c', 'M640 239.87v31.26A15.88 15.88 0 0 1 624.14 287H15.87A15.88 15.88 0 0 1 0 271.13v-31.26A15.88 15.88 0 0 1 15.87 224h608.27A15.88 15.88 0 0 1 640 239.87z'],
  iconName: 'solid-horizontal-rule' as any,
  prefix
}

const solidFolders: IconDefinition = {
  icon: [640, 512, [], 'f660', 'M96 336V128H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-48H176c-44.11 0-80-35.89-80-80zM592 64H400L336 0H176c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48z'],
  iconName: 'solid-folders' as any,
  prefix
}

const duotoneQuestionCircle: IconDefinition = {
  icon: [512, 512, [], 'f059', ['M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 422a46 46 0 1 1 46-46 46.05 46.05 0 0 1-46 46zm40-131.33V300a12 12 0 0 1-12 12h-56a12 12 0 0 1-12-12v-4c0-41.06 31.13-57.47 54.65-70.66 20.17-11.31 32.54-19 32.54-34 0-19.82-25.27-33-45.7-33-27.19 0-39.44 13.14-57.3 35.79a12 12 0 0 1-16.67 2.13L148.82 170a12 12 0 0 1-2.71-16.26C173.4 113 208.16 90 262.66 90c56.34 0 116.53 44 116.53 102 0 77-83.19 78.21-83.19 106.67z', 'M256 338a46 46 0 1 0 46 46 46 46 0 0 0-46-46zm6.66-248c-54.5 0-89.26 23-116.55 63.76a12 12 0 0 0 2.71 16.24l34.7 26.31a12 12 0 0 0 16.67-2.13c17.86-22.65 30.11-35.79 57.3-35.79 20.43 0 45.7 13.14 45.7 33 0 15-12.37 22.66-32.54 34C247.13 238.53 216 254.94 216 296v4a12 12 0 0 0 12 12h56a12 12 0 0 0 12-12v-1.33c0-28.46 83.19-29.67 83.19-106.67 0-58-60.19-102-116.53-102z']],
  iconName: 'duotone-question-circle' as any,
  prefix
}
const duotoneBook: IconDefinition = {
  icon: [448, 512, [], 'f02d', ['M96 448c-19.2 0-32-12.8-32-32s16-32 32-32h319.33c-1.93 16.24-1.76 48.38.53 64z', 'M96 384h328a24 24 0 0 0 24-24V32a32 32 0 0 0-32-32H96A96 96 0 0 0 0 96v320a96 96 0 0 0 96 96h328a24 24 0 0 0 24-24v-16a24 24 0 0 0-24-24H96c-19.2 0-32-12.8-32-32s16-32 32-32zm32-250a6 6 0 0 1 6-6h212a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H134a6 6 0 0 1-6-6zm0 64a6 6 0 0 1 6-6h212a6 6 0 0 1 6 6v20a6 6 0 0 1-6 6H134a6 6 0 0 1-6-6z']],
  iconName: 'duotone-book' as any,
  prefix
}
const duotoneBookOpen: IconDefinition = {
  icon: [576, 512, [], 'f518', ['M576 62.75v337.84c0 16.23-13.13 29.77-30 30.66-49.47 2.6-149.52 12.1-218.7 46.92-10.65 5.36-23.28-1.94-23.28-13.49V100.81a15.37 15.37 0 0 1 7.27-13.17c67.24-41.16 176.16-52.48 231-55.59C560.64 31 576 45 576 62.75z', 'M264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31 0 45 0 62.75V400.6c0 16.24 13.13 29.78 30 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63a15.05 15.05 0 0 0-7.25-12.99z']],
  iconName: 'duotone-book-open' as any,
  prefix
}
const duotoneUserHeadset: IconDefinition = {
  icon: [448, 512, [], 'f82d', ['M416 192v16a112.15 112.15 0 0 1-112 112h-96a32 32 0 0 1 0-64h32a32 32 0 0 1 32 32h32a80.09 80.09 0 0 0 80-80v-16c0-88.22-71.78-160-160-160S64 103.78 64 192v16a16 16 0 0 1-32 0v-16C32 86.13 118.13 0 224 0s192 86.13 192 192z', 'M320 352h-23.1a174.1 174.1 0 0 1-145.8 0H128A128 128 0 0 0 0 480a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32 128 128 0 0 0-128-128zm-175.65-60.53c-.06-1.17-.35-2.28-.35-3.47a64.07 64.07 0 0 1 64-64h32a64 64 0 0 1 55.41 32H304a48.05 48.05 0 0 0 48-48v-16a128 128 0 0 0-256 0c0 40.42 19.1 76 48.35 99.47z']],
  iconName: 'duotone-user-headset' as any,
  prefix
}
const duotoneLayerGroup: IconDefinition = {
  icon: [512, 512, [], 'f5fd', ['M12.41 236.31L70.51 210l161.63 73.27a57.64 57.64 0 0 0 47.72 0L441.5 210l58.09 26.33c16.55 7.5 16.55 32.5 0 40L266.64 381.9a25.68 25.68 0 0 1-21.29 0L12.41 276.31c-16.55-7.5-16.55-32.5 0-40z', 'M12.41 148l232.94 105.7a25.61 25.61 0 0 0 21.29 0L499.58 148c16.55-7.51 16.55-32.52 0-40L266.65 2.32a25.61 25.61 0 0 0-21.29 0L12.41 108c-16.55 7.5-16.55 32.52 0 40zm487.18 216.11l-57.87-26.23-161.86 73.37a57.64 57.64 0 0 1-47.72 0L70.29 337.88l-57.88 26.23c-16.55 7.5-16.55 32.5 0 40L245.35 509.7a25.68 25.68 0 0 0 21.29 0l233-105.59c16.5-7.5 16.5-32.5-.05-40z']],
  iconName: 'duotone-layer-group' as any,
  prefix
}
const duotoneUsers: IconDefinition = {
  icon: [640, 512, [], 'f0c0', ['M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z', 'M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z']],
  iconName: 'duotone-users' as any,
  prefix
}
const duotoneBolt: IconDefinition = {
  icon: [320, 512, [], 'f0e7', ['M224 24.19a23.89 23.89 0 0 1-.79 6L180.61 160l-37.9 128H24a24 24 0 0 1-24-24 24.45 24.45 0 0 1 .21-3.2l32-240A24 24 0 0 1 56 0h144a24.09 24.09 0 0 1 24 24.19z', 'M319.93 184.07a23.93 23.93 0 0 1-3.22 11.93l-176 304a24 24 0 0 1-44.1-17.5l46.1-194.5 37.9-128H296a24 24 0 0 1 23.93 24.07z']],
  iconName: 'duotone-bolt' as any,
  prefix
}
const duotoneFlagCheckered: IconDefinition = {
  icon: [512, 512, [], 'f11e', ['M96 298.66v95c28.3-12.1 63.6-22.1 114.4-22.1a144.77 144.77 0 0 1 29.6 3.26v-95a144.77 144.77 0 0 0-29.6-3.26c-50.8 0-86.1 10-114.4 22.1zM184.3 64c-19.3 0-42.3 0-68.14 12.36A56.18 56.18 0 0 1 96 102v93.66c28.3-12.1 63.6-22.1 114.4-22.1a144.77 144.77 0 0 1 29.6 3.26V71.64C223 67.2 204.69 64 184.3 64zm191.3 342.36c48.2 0 86.7-16.3 122.5-40.9a31.88 31.88 0 0 0 13.8-26.4v-76c-35.79 24.58-88.14 48.3-136.3 48.3-2.57 0-5.09-.07-7.6-.16v95c2.51.09 5 .16 7.6.16zm136.3-246.3V96c.1-23.35-24.2-38.85-45.4-29-29.1 13.4-63.89 27-98.5 30.76V208.2c2.51.09 5 .16 7.6.16 48.16 0 100.51-23.72 136.3-48.3zM240 176.82v103c39.58 8.25 77.24 29.4 128 31.38v-103c-50.76-1.98-88.42-23.13-128-31.38z', 'M210.4 173.6c-50.8 0-86.1 10-114.4 22.1V102a56 56 0 1 0-64 0v388a22 22 0 0 0 22 22h20a22 22 0 0 0 22-22V298.7c28.3-12.1 63.6-22.1 114.4-22.1a144.77 144.77 0 0 1 29.6 3.26v-103a144.77 144.77 0 0 0-29.6-3.26zM240 374.82c39.58 8.25 77.24 29.4 128 31.38v-95c-50.76-2-88.42-23.13-128-31.38zM368 97.76a169.27 169.27 0 0 1-18.5 1c-37.32 0-70.17-16.92-109.5-27.17v105.23c39.58 8.25 77.24 29.4 128 31.38zm143.9 146.3v-84c-35.79 24.58-88.14 48.3-136.3 48.3-2.57 0-5.09-.07-7.6-.16v103c2.51.09 5 .16 7.6.16 48.2 0 100.6-23.76 136.4-48.36v-17.16c-.06-.57-.09-1.16-.1-1.78z']],
  iconName: 'duotone-flag-checkered' as any,
  prefix
}
const duotoneExchange: IconDefinition = {
  icon: [512, 512, [], 'f0ec', ['M488 384H106l30.47 27.73a24 24 0 0 1 .47 34.4L126.13 457a24 24 0 0 1-33.94 0L9.37 374.63a32 32 0 0 1 0-45.26L92.19 247a24 24 0 0 1 33.94 0L137 257.87a24 24 0 0 1-.47 34.4L106 320h382a24 24 0 0 1 24 24v16a24 24 0 0 1-24 24z', 'M0 168v-16a24 24 0 0 1 24-24h382l-30.5-27.73a24 24 0 0 1-.47-34.4L385.87 55a24 24 0 0 1 33.94 0l82.82 82.34a32 32 0 0 1 0 45.26L419.81 265a24 24 0 0 1-33.94 0L375 254.13a24 24 0 0 1 .47-34.4L406 192H24a24 24 0 0 1-24-24z']],
  iconName: 'duotone-exchange' as any,
  prefix
}
const duotoneTags: IconDefinition = {
  icon: [640, 512, [], 'f02c', ['M497.94 225.94L286.06 14.06A48 48 0 0 0 252.12 0H48A48 48 0 0 0 0 48v204.12a48 48 0 0 0 14.06 33.94l211.88 211.88a48 48 0 0 0 67.88 0l204.12-204.12a48 48 0 0 0 0-67.88zM112 160a48 48 0 1 1 48-48 48 48 0 0 1-48 48z', 'M625.94 293.82L421.82 497.94a48 48 0 0 1-67.88 0l-.36-.36 174.06-174.06a90 90 0 0 0 0-127.28L331.4 0h48.72a48 48 0 0 1 33.94 14.06l211.88 211.88a48 48 0 0 1 0 67.88z']],
  iconName: 'duotone-tags' as any,
  prefix
}
const duotoneCode: IconDefinition = {
  icon: [640, 512, [], 'f121', ['M422.12 18.16a12 12 0 0 1 8.2 14.9l-136.5 470.2a12 12 0 0 1-14.89 8.2l-61-17.7a12 12 0 0 1-8.2-14.9l136.5-470.2a12 12 0 0 1 14.89-8.2z', 'M636.23 247.26l-144.11-135.2a12.11 12.11 0 0 0-17 .5L431.62 159a12 12 0 0 0 .81 17.2L523 256l-90.59 79.7a11.92 11.92 0 0 0-.81 17.2l43.5 46.4a12 12 0 0 0 17 .6l144.11-135.1a11.94 11.94 0 0 0 .02-17.54zm-427.8-88.2l-43.5-46.4a12 12 0 0 0-17-.5l-144.11 135a11.94 11.94 0 0 0 0 17.5l144.11 135.1a11.92 11.92 0 0 0 17-.5l43.5-46.4a12 12 0 0 0-.81-17.2L117 256l90.6-79.7a11.92 11.92 0 0 0 .83-17.24z']],
  iconName: 'duotone-code' as any,
  prefix
}
const duotoneGift: IconDefinition = {
  icon: [512, 512, [], 'f06b', ['M224 320v160H64a32 32 0 0 1-32-32V320zm0-160H32a32 32 0 0 0-32 32v80a16 16 0 0 0 16 16h208zm256 0H288v128h208a16 16 0 0 0 16-16v-80a32 32 0 0 0-32-32zM288 480h160a32 32 0 0 0 32-32V320H288z', 'M224 480h64V320h-64zM359.54 32c-41.82 0-68.86 21.3-103.54 68.3-34.68-47-61.72-68.3-103.54-68.3C103.71 32 64 71.5 64 120a85.65 85.65 0 0 0 10.15 40h78.21a40 40 0 1 1 0-80c20 0 34.78 3.3 86.55 80H224v128h64V160h-15c51.67-76.5 66-80 86.55-80a40 40 0 1 1 0 80h78.31A86.86 86.86 0 0 0 448 120c0-48.5-39.71-88-88.46-88z']],
  iconName: 'duotone-gift' as any,
  prefix
}

const duotoneLockAlt: IconDefinition = {
  icon: [448, 512, [], 'f30d', ['M152 225H72v-72C72 69.2 140.2 1 224 1s152 68.2 152 152v72h-80v-72a72 72 0 0 0-144 0z', 'M400 225H48a48 48 0 0 0-48 48v192a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V273a48 48 0 0 0-48-48zM264 392a40 40 0 0 1-80 0v-48a40 40 0 0 1 80 0z']],
  iconName: 'duotone-lock-alt' as any,
  prefix
}

const duotoneUserGraduate: IconDefinition = {
  icon: [448, 512, [], 'f501', ['M351.75 168a128 128 0 0 1-255.5 0z', 'M13.6 79.83l6.4 1.5v58.4c-7 4.19-12 11.5-12 20.27 0 8.4 4.6 15.4 11.1 19.71L3.5 242c-1.7 6.9 2.1 14 7.6 14h41.8c5.5 0 9.3-7.1 7.6-14l-15.6-62.27C51.4 175.42 56 168.42 56 160c0-8.79-5-16.1-12-20.29V87.12l52 12.53V136h256V99.65l82.3-19.82c18.2-4.41 18.2-27.1 0-31.5l-190.4-46a85.9 85.9 0 0 0-39.7 0L13.6 48.23c-18.1 4.39-18.1 27.19 0 31.6zm305.8 240.79L224 416l-95.4-95.4C57.1 323.73 0 382.23 0 454.42V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-9.6c0-72.17-57.1-130.67-128.6-133.78z']],
  iconName: 'duotone-user-graduate' as any,
  prefix
}

export default [
  arrowFromRight,
  arrowToRight,
  compressAlt,
  connection,
  crossSquare,
  dragHandle,
  duotoneBolt,
  duotoneBook,
  duotoneBookOpen,
  duotoneCode,
  duotoneExchange,
  duotoneFlagCheckered,
  duotoneGift,
  duotoneLayerGroup,
  duotoneLockAlt,
  duotoneQuestionCircle,
  duotoneTags,
  duotoneUserGraduate,
  duotoneUserHeadset,
  duotoneUsers,
  expand,
  fileAlt,
  fileUser,
  flowReply,
  horizontalRule,
  questionCircle,
  regularAngleDoubleDown,
  regularAngleDoubleUp,
  regularAt,
  regularCheck,
  regularCommentAltLines,
  regularMinus,
  regularMinusCircle,
  regularPlus,
  regularRepeat,
  regularSearch,
  regularSparkles,
  regularSquare,
  regularTimes,
  regularUndo,
  regularWaveSine,
  regularWaveSquare,
  selection,
  solidCommentAltCheck,
  solidCommentAltDots,
  solidCommentAltLines,
  solidFolders,
  solidHorizontalRule,
  solidLockAlt,
  solidRepeat,
  solidSackDollar,
  solidSledding,
  solidTriangle,
  sync,
  text
]
